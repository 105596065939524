import { API_URL, createRequestActionTypes } from "../../../actions/index";
export const USER_API_URL = `${API_URL}/api/v1/user`;
export const CAMPAIGN_API_URL = `${API_URL}/api/v1/campaign/display`;
export const COMPLETE_INVITATION_URL = `${API_URL}/api/v1/user/invitation`;

export const REGISTER_DUPLICATE_ERROR_MESSAGE = "Account already exists.";
export const REGISTER_ERROR_MESSAGE = "Failed to create account.";
export const INVITATION_REGISTER_ERROR_MESSAGE =
  "Failed to register account. Invitation link is invalid or has already been used.";
export const REGISTER_SUCCESS_MESSAGE = "Account successfully created.";

export const signupActionTypes = {
  register: createRequestActionTypes("REGISTER"),
  getCampaignDisplayInfo: createRequestActionTypes("GET_CAMPAIGN_DISPLAY_INFO"),
  completeInvitation: createRequestActionTypes("COMPLETE_INVITATION"),
  clearErrorMessages: createRequestActionTypes("CLEAR_SIGNUP_ERROR_MSG"),
  clearAccountErrors: createRequestActionTypes("CLEAR_ACCOUNT_ERRORS"),
};

export const countryOptions = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "GB" },
  { label: "Canada", value: "CA" },
  { label: "Australia", value: "AU" },
  { label: "Mexico", value: "MX" },
  { label: "France", value: "FR" },
  { label: "Hong Kong", value: "HK" },
  { label: "Germany", value: "DE" },
  { label: "Italy", value: "IT" },
  { label: "Israel", value: "IL" },
  { label: "Spain", value: "ES" },
  { label: "Netherlands", value: "NL" },
  { label: "New Zealand", value: "NZ" },
  { label: "Belgium", value: "BE" },
  { label: "Austria", value: "AT" },
  { label: "Finland", value: "FI" },
  { label: "Greece", value: "GR" },
  { label: "Ireland", value: "IE" },
  { label: "Luxembourg", value: "LU" },
  { label: "Portugal", value: "PT" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
].sort((a, b) => (a.label > b.label ? 1 : -1));
