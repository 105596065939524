import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { Field, Formik } from "formik";
import {
  RenderPasswordTextField,
  RenderSingleSelect,
  RenderTextField,
} from "../../../components";
import { register } from "../../signup/actions";

const StepSix = ({ portalAccountRef, ...props }) => {
  const { hubspotCreatorId } = useParams();

  const [country, setCountry] = useState({
    label: "",
    value: "",
  });
  const creator = useSelector((state) => state.onboarding.creator);
  const initialValues = {
    email: creator?.email || "",
    country: "",
    password: "",
    password2: "",
  };

  const validate = useCallback(
    (values) => {
      const errors = {};

      if (!values?.email || values?.email.trim() === "") {
        errors.email = "This field is required";
      } else if (
        values?.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(values?.email)
      ) {
        errors.email = "Invalid email";
      }

      if (!values?.password || values?.password.trim() === "") {
        errors.password = "This field is required";
      } else if (values?.password.trim().length < 8) {
        errors.password = "Must be at least 8 characters";
      }

      if (!values?.password2 || values?.password2.trim() === "") {
        errors.password2 = "This field is required";
      } else if (
        values?.password &&
        values?.password2 &&
        values?.password.trim() !== values?.password2.trim()
      ) {
        errors.password2 = "Password confirmation doesn't match";
      }

      if (!country.value || country.value.trim() === "") {
        errors.country = "This field is required";
      }

      return errors;
    },
    [country.value],
  );

  const countryOptions = useMemo(
    () =>
      [
        { label: "United States", value: "US" },
        { label: "United Kingdom", value: "GB" },
        { label: "Canada", value: "CA" },
        { label: "Australia", value: "AU" },
        { label: "Mexico", value: "MX" },
        { label: "France", value: "FR" },
        { label: "Hong Kong", value: "HK" },
        { label: "Germany", value: "DE" },
        { label: "Italy", value: "IT" },
        { label: "Spain", value: "ES" },
        { label: "Netherlands", value: "NL" },
        { label: "Bew Zealand", value: "NZ" },
        { label: "Belgium", value: "BE" },
        { label: "Austria", value: "AT" },
        { label: "Finland", value: "FI" },
        { label: "Greece", value: "GR" },
        { label: "Ireland", value: "IE" },
        { label: "Luxembourg", value: "LU" },
        { label: "Portugal", value: "PT" },
        { label: "Slovakia", value: "SK" },
        { label: "Slovenia", value: "SI" },
      ].sort((a, b) => (a.label > b.label ? 1 : -1)),
    [],
  );

  const handleCountrySelectChange = useCallback((value) => {
    setCountry(value);
  }, []);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      register({
        ...values,
        country: country.value,
        hubspotCreatorId,
      });
      setSubmitting(false);
    },
    [country.value, hubspotCreatorId],
  );

  return (
    <div className="step-six px-3">
      <h1 className="heading title">
        Create Your
        <span className="gradient new-line">Portal Login.</span>
      </h1>
      <p className="description">
        Create your login to our Creator Portal to get notified of all of your
        deals/opportunities, manage your contracts, and connect your bank
        account. This will be your home base. Your first $50 dollars is waiting
        upon completion.
      </p>
      <Formik
        innerRef={portalAccountRef}
        initialValues={initialValues}
        enableReinitialize={true}
        initialErrors={{
          email: "This field is required",
          password: "This field is required",
          password2: "This field is required",
          country: "This field is required",
        }}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnBlur={true}
        validateOnMount={true}
      >
        {({
          touched,
          errors,
          validateForm,
          setTouched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          ...props
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <label htmlFor="email" className="form-label mx-2">
                Select Email
                {errors?.email && touched?.email && (
                  <span className="required ml-2">
                    *{errors?.email || "A valid email is required"}
                  </span>
                )}
              </label>
              <Field
                name="email"
                className={errors?.email && touched?.email ? "error" : ""}
                component={RenderTextField}
                autoComplete="email"
              />

              <label htmlFor="country" className="form-label mt-3 mx-2">
                Select Country
                {errors?.country && touched?.country && (
                  <span className="required ml-2">
                    *{errors?.country || "Country is required"}
                  </span>
                )}
              </label>
              <Field
                name="country"
                className={errors?.country && touched?.country ? "error" : ""}
                value={country}
                onChange={handleCountrySelectChange}
                onBlur={handleBlur}
                options={countryOptions}
                component={RenderSingleSelect}
              />

              <label htmlFor="password" className="form-label mt-3 mx-2">
                Set Your Password
                {errors?.password && touched?.password && (
                  <span className="required ml-2">
                    *{errors?.password || "A valid password is required"}
                  </span>
                )}
              </label>
              <Field
                name="password"
                className={errors?.password && touched?.password ? "error" : ""}
                component={RenderPasswordTextField}
                autoComplete="new-password"
              />

              <label htmlFor="password2" className="form-label mt-3 mx-2">
                Confirm Your Password
                {errors?.password2 && touched?.password2 && (
                  <span className="required ml-2">
                    *{errors?.password2 || "Passwords must match"}
                  </span>
                )}
              </label>
              <Field
                name="password2"
                className={
                  errors?.password2 && touched?.password2 ? "error" : ""
                }
                component={RenderPasswordTextField}
                autoComplete="new-password"
              />
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepSix;
