import {
  initializeFaro,
  getWebInstrumentations,
  ReactIntegration,
  createReactRouterV5Options,
  FaroRoute,
  LogLevel,
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { Route } from "react-router-dom";
import history from "../history";

const FARO_URL = process.env.REACT_APP_FARO_URL;
const FARO_APP_NAME = process.env.REACT_APP_FARO_APP_NAME;

const FARO_ENABLED =
  (process.env.REACT_APP_ENV === "staging" ||
    process.env.REACT_APP_ENV === "development") &&
  !!FARO_URL &&
  !!FARO_APP_NAME;

let faroInstance = null;

export const initializeFaroMonitoring = () => {
  if (!FARO_ENABLED) {
    return null;
  }
  faroInstance = initializeFaro({
    url: FARO_URL,
    ignoreUrls: [
      new RegExp(`^(?!.*${window.location.hostname})`),
      new RegExp(`.*${FARO_URL}`),
      new RegExp(".*google-analytics\.com"),
    ],
    app: {
      name: FARO_APP_NAME,
      version: process.env.REACT_APP_VERSION || "0.1.0",
      environment: process.env.REACT_APP_ENV,
    },
    sessionTracking: {
      samplingRate: 1,
      persistent: true,
    },
    trackGeolocation: true,
    consoleInstrumentation: {
      disabledLevels: [
        LogLevel.DEBUG,
        LogLevel.TRACE,
        LogLevel.LOG,
        LogLevel.INFO,
        LogLevel.WARN,
      ],
    },
    trackWebVitalsAttribution: true,
    instrumentations: [
      ...getWebInstrumentations({
        enablePerformanceInstrumentation: true,
      }),
      new ReactIntegration({
        router: createReactRouterV5Options({
          history,
          Route,
        }),
      }),
      new TracingInstrumentation(),
    ],
  });

  return faroInstance;
};

export const setUserData = (userData) => {
  if (!FARO_ENABLED || !faroInstance || !userData) return;

  try {
    faroInstance.api.setUser({
      user_id: userData._id,
      email: userData.email,
    });
  } catch (error) {
    // Silently fail
  }
};

export const InstrumentedRoute = FARO_ENABLED ? FaroRoute : Route;

export default initializeFaroMonitoring;
