import Header from "../modules/header/index";
import DashboardComponent from "../modules/dashboard/index";
import PayoutsComponent from "../modules/payouts/index";
import SettingsComponent from "../modules/settings/index";
// import LoginComponent from "../modules/login/index";
// import SignupComponent from "../modules/signup/index";
import ErrorComponent from "../modules/error";
import GlobalLoadScreen from "../modules/globalLoadScreen";
import { Router, Switch } from "react-router-dom";
import PublicRoute from "./publicRoute";
import history from "../history";
import Signup from "../modules/signup";
import Logout from "../modules/login/logout";
import Login from "../modules/login";
import OnboardingReturn from "../modules/signup/stripeOnboardingReturn";
import CreatorInvitation from "../modules/signup/creatorInvitation";
import { connect } from "react-redux";
import PrivateRoute from "./privateRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import { readAuthCookie } from "../actions";
import LogoutErrorComponent from "../modules/error/LogoutError";
import { InstrumentedRoute, setUserData } from "../utils/faro";

import TagManager from "react-gtm-module";
import { useEffect } from "react";
import LoginMagicLink from "../modules/login/LoginMagicLink";
import VerifyAuthLink from "../modules/login/VerifyAuthLink";
import AccountRecovery from "../modules/login/AccountRecovery";
import AccountRecoveryComplete from "../modules/login/AccountRecoveryComplete";
import Campaigns from "../modules/campaigns";
import CampaignContentPreview from "../modules/campaigns/CampaignContentPreview";
import MediaKits from "../modules/mediaKits";
import Deals from "../modules/deals";
import Onboarding from "../modules/onboarding";
import Agreements from "../modules/agreements";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AppRoutes = ({ loginUser, ...props }) => {
  useEffect(() => {
    return history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }, []);

  // Set user data for Faro when the user changes
  useEffect(() => {
    if (loginUser) {
      setUserData(loginUser);
    }
  }, [loginUser]);

  const userCookie = readAuthCookie();
  const isAuthenticated = !!loginUser && !!userCookie;
  const dealsEnabled = isAuthenticated && !!loginUser?.talentManagementEmail;

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GA_TAG_MANAGER_ID,
    dataLayer: {
      loginUser,
      isAuthenticated,
    },
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <Router history={history}>
      <div
        className={
          isAuthenticated ? "outer-header show" : "outer-header-hidden"
        }
      >
        <Header />
      </div>

      <div
        id="app"
        style={{
          transition: "all 1s cubic-bezier(0, 1, 0.5, 1)",
        }}
        className="content mt-5"
      >
        <Elements stripe={stripePromise}>
          <Switch>
            <PublicRoute
              exact
              path="/"
              component={Login}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/signup"
              component={Signup}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/onboarding/:hubspotCreatorId"
              component={Onboarding}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/creatorInvitation/:id/:token"
              component={CreatorInvitation}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/magicLink"
              component={LoginMagicLink}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/magicLink/:id/:token"
              component={VerifyAuthLink}
              userCookie={userCookie}
            />
            <InstrumentedRoute
              exact
              path="/verify/:id/:token"
              component={VerifyAuthLink}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/recovery"
              component={AccountRecovery}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/recovery/:id/:token"
              component={AccountRecoveryComplete}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/stripeOnboardingReturn"
              component={OnboardingReturn}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/logout"
              component={Logout}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={DashboardComponent}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/mediaKits"
              component={MediaKits}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/mediaKits/manage/:managingMediaKit_id"
              component={MediaKits}
              userCookie={userCookie}
            />
            <InstrumentedRoute
              exact
              path="/mediaKits/:mediaKit_id"
              component={MediaKits}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/agreements/:agreement_id?"
              component={Agreements}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/campaigns/:campaignAssignment_id?"
              component={Campaigns}
              userCookie={userCookie}
            />
            <PublicRoute
              exact
              path="/campaignContent/preview/:campaignContent_id"
              component={CampaignContentPreview}
              // userCookie={userCookie}
            />
            {dealsEnabled && (
              <InstrumentedRoute
                path="/deals"
                render={({ match: { url } }) => (
                  <>
                    <PrivateRoute
                      exact
                      path={`${url}/`}
                      component={Deals}
                      userCookie={userCookie}
                    />
                    <PrivateRoute
                      exact
                      path={`${url}/:deal_id`}
                      component={Deals}
                      userCookie={userCookie}
                    />
                  </>
                )}
              />
            )}
            <PrivateRoute
              exact
              path="/payouts"
              component={PayoutsComponent}
              userCookie={userCookie}
            />
            <PrivateRoute
              exact
              path="/settings"
              component={SettingsComponent}
              userCookie={userCookie}
            />
            <InstrumentedRoute
              exact
              path="/loading"
              component={GlobalLoadScreen}
            />
            <InstrumentedRoute
              exact
              path="/authError"
              component={LogoutErrorComponent}
            />
            <InstrumentedRoute exact path="*" component={ErrorComponent} />
          </Switch>
        </Elements>
      </div>
      <ToastContainer limit={5} />
    </Router>
  );
};

const mapStateToProps = (state) => ({
  loginUser: state.login.loginUser,
  userLoading: state.login.loading,
  dashboardLoading: state.dashboard.loading,
  campaignsLoading: state.campaigns.loading,
  payoutsLoading:
    state.payouts.loading ||
    state.payouts.loadingDeposits ||
    state.payouts.loadingAssignments,
  settingsLoading: state.settings.loading,
  signupLoading: state.signup.loading,
});
export default connect(mapStateToProps, null)(AppRoutes);
