import {
  actionCreator,
  checkHttpStatus,
  publicHeaders,
  showErrorMessage,
  showSuccessMessage,
} from "../../../actions";
import {
  signupActionTypes,
  USER_API_URL,
  CAMPAIGN_API_URL,
  COMPLETE_INVITATION_URL,
  REGISTER_DUPLICATE_ERROR_MESSAGE,
  REGISTER_SUCCESS_MESSAGE,
  REGISTER_ERROR_MESSAGE,
  INVITATION_REGISTER_ERROR_MESSAGE,
} from "../constants";

export const register = (userData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.register.REQUEST));
    if (!userData?.token) {
      showErrorMessage(REGISTER_ERROR_MESSAGE);
      dispatch(actionCreator(signupActionTypes.register.FAILURE));
      return;
    }
    fetch(USER_API_URL, {
      method: "POST",
      headers: publicHeaders,
      body: JSON.stringify(userData),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response?.error) {
          if (response.statusCode === 409) {
            dispatch(
              actionCreator(signupActionTypes.register.FAILURE, {
                duplicateAccountEmail: userData?.email,
              }),
            );
            showErrorMessage(REGISTER_DUPLICATE_ERROR_MESSAGE);
            return;
          }
          dispatch(actionCreator(signupActionTypes.register.FAILURE));
          showErrorMessage(REGISTER_ERROR_MESSAGE);
          return;
        }
        showSuccessMessage(REGISTER_SUCCESS_MESSAGE);
        dispatch(actionCreator(signupActionTypes.register.SUCCESS, response));
      })
      .catch((error) => {
        showErrorMessage(REGISTER_ERROR_MESSAGE);
        dispatch(actionCreator(signupActionTypes.register.FAILURE));
      });
  };
};

export const completeInvitation = (userData) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.completeInvitation.REQUEST));
    fetch(COMPLETE_INVITATION_URL, {
      method: "PUT",
      headers: publicHeaders,
      body: JSON.stringify(userData),
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          if (response.statusCode === 409) {
            dispatch(
              actionCreator(signupActionTypes.completeInvitation.FAILURE, {
                duplicateAccountEmail: userData?.email,
              }),
            );
            showErrorMessage(REGISTER_DUPLICATE_ERROR_MESSAGE);
            return;
          }
          showErrorMessage(INVITATION_REGISTER_ERROR_MESSAGE);
          dispatch(actionCreator(signupActionTypes.completeInvitation.FAILURE));
          return;
        }
        showSuccessMessage(REGISTER_SUCCESS_MESSAGE);
        dispatch(
          actionCreator(signupActionTypes.completeInvitation.SUCCESS, response),
        );
      })
      .catch((error) => {
        showErrorMessage(INVITATION_REGISTER_ERROR_MESSAGE);
        dispatch(
          actionCreator(signupActionTypes.completeInvitation.FAILURE, error),
        );
      });
  };
};

export const getCampaignDisplayInfo = (campaign_id) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.getCampaignDisplayInfo.REQUEST));
    const url = new URL(CAMPAIGN_API_URL);
    const params = url.searchParams;
    params.append("_id", campaign_id);
    fetch(url?.toString(), {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => {
        if (response.error) {
          dispatch(
            actionCreator(signupActionTypes.getCampaignDisplayInfo.FAILURE),
          );
          return;
        }
        dispatch(
          actionCreator(
            signupActionTypes.getCampaignDisplayInfo.SUCCESS,
            response?.result,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(
            signupActionTypes.getCampaignDisplayInfo.FAILURE,
            error,
          ),
        );
      });
  };
};

export const clearAccountErrors = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(signupActionTypes.clearAccountErrors.SUCCESS));
  };
};
